import axios from 'axios';
import { client } from 'index';
import { env } from '../env';
import { deleteAllCookies } from './lib';
import { message } from 'antd';

const httpService = axios.create({
  withCredentials: true,
  baseURL: env.REACT_APP_REST_API,
  headers: {
    'X-Use-Cookies': 'true',
  },
});

httpService.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err?.response?.status === 400) {
      message.error(err.message);
    } else if (err?.response?.status === 401 || err?.response?.status === 403) {
      client.resetStore();
      deleteAllCookies();
      window.location.reload();
    } else if (err?.response?.status === 409) {
      client.resetStore();
      deleteAllCookies();
      window.location.href =
        '/login?message=Another person used your credential to login. Please use your own account to login.';
    } else if (err?.response?.status === 500) {
      message.error(err?.response?.data?.message);
    }
    return Promise.reject(err);
  }
);

export { httpService };
