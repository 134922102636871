/* eslint-disable jsx-a11y/anchor-is-valid */
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Popover, Space, Tooltip } from 'antd';
import { useDeleteSettingsRoleFromProjectMutation } from 'generated/graphql';
import React from 'react';
import { successMessage } from 'utils/message';
import { GET_ROLES } from '../../../../graphql/queries/settings';

type TablesDeleteButtonProps = {
  onEditRole: (value: string) => void;
  text: { name: string };
};

const TablesDeleteButton: React.FC<TablesDeleteButtonProps> = ({ onEditRole, text }) => {
  const [isPopOver, setIsPopOver] = React.useState(false);
  const [deleteRole] = useDeleteSettingsRoleFromProjectMutation({
    onCompleted: (data) => {
      successMessage('Role Deleted!');
      setIsPopOver(false);
    },
  });

  const onDeleteRole = async (role) => {
    await deleteRole({
      variables: {
        role,
      },
      refetchQueries: [GET_ROLES],
    });
  };

  const hidePopOver = (value) => {
    setIsPopOver(value);
  };
  return (
    <Space>
      <Tooltip title="Edit Role">
        <Button
          icon={<EditOutlined style={{ cursor: 'pointer' }} />}
          onClick={() => onEditRole(text.name)}
        >
          Edit
        </Button>
      </Tooltip>
      <Tooltip title="Delete Role">
        <Popover
          title="Are You Sure?"
          trigger="click"
          placement="left"
          open={isPopOver}
          onOpenChange={hidePopOver}
          content={
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <a
                onClick={() => {
                  setIsPopOver(false);
                }}
              >
                Close
              </a>
              <a onClick={() => onDeleteRole(text?.name)} className="ml-1">
                Yes
              </a>
            </div>
          }
        >
          <Button
            icon={<DeleteOutlined />}
            onClick={() => {
              setIsPopOver(true);
            }}
          >
            DELETE
          </Button>
        </Popover>
      </Tooltip>
    </Space>
  );
};

export default TablesDeleteButton;
