import {
  Plugin_System_Type_Enum,
  PluginConfigEnvVarsPayload,
  PluginDetailsFields,
  useUpsertPluginDetailsMutation,
} from 'generated/graphql';
import { Button, Form, Input, Typography } from 'antd';
import { useState } from 'react';
import { successMessage } from '../../../../../utils/message';

const { Text } = Typography;

type PluginsDrawerProps = {
  plugin: PluginDetailsFields;
  pluginType: Plugin_System_Type_Enum;
};

const PluginSettingsDrawer: React.FC<PluginsDrawerProps> = ({ plugin, pluginType }) => {
  const [upsertPluginMutation, { loading }] = useUpsertPluginDetailsMutation({
    onCompleted: (data) => {
      successMessage(data);
    },
  });

  const onFinish = async (values) => {
    const env_vars: PluginConfigEnvVarsPayload[] = Object.entries(values).map(([key, value]) => ({
      key: key || undefined,
      value: typeof value === 'string' ? value : undefined,
    }));
    await upsertPluginMutation({
      variables: {
        id: plugin.id,
        system_type: pluginType,
        env_vars: env_vars,
      },
    });
  };

  return (
    <div>
      <Form
        name="env_vars"
        layout="vertical"
        //onValuesChange={onValuesChange}
        onFinish={onFinish}
        initialValues={{ remember: true }}
        //validateMessages={validateMessages}
      >
        {plugin?.env_vars === undefined ? (
          <Form.Item>
            <Text>Nothing to Configure Here</Text>
          </Form.Item>
        ) : (
          <>
            <Form.Item>
              <Text>Environment Variables</Text>
            </Form.Item>
            {plugin?.env_vars?.map((env, index) => (
              <Form.Item
                key={env.key}
                name={env.key}
                label={env.key}
                initialValue={env.value ?? ''}
                //rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            ))}
            <Form.Item style={{ position: 'absolute', bottom: 0, width: '85%' }}>
              <Button
                block
                type="primary"
                htmlType="submit"
                style={{ display: 'block', margin: '0 auto' }}
                loading={loading}
              >
                UPDATE
              </Button>
            </Form.Item>
          </>
        )}
      </Form>
    </div>
  );
};

export default PluginSettingsDrawer;
