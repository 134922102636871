import { Button, Card, Space, Switch, Tag } from 'antd';
import styles from './cards.module.scss';
import {
  Plugin_Activation_Type_Enum,
  Plugin_Load_Type_Enum,
  Plugin_System_Type_Enum,
  Plugin_Type_Enum,
  PluginDetailsFields,
  useUpsertPluginDetailsMutation,
} from '../../generated/graphql';
import React from 'react';
import PluginUploadBtn from './PluginStatusButton';
import { capitalize } from 'lodash';
import { gqlErrorMessage, successMessage } from '../../utils/message';

const { Meta } = Card;

type AddonCardProps = {
  plugin: PluginDetailsFields;
  pluginType: Plugin_System_Type_Enum;
  showDrawer: (plugin: PluginDetailsFields) => void;
};

const PluginCard: React.FC<AddonCardProps> = ({ plugin, pluginType, showDrawer }) => {
  const getLoadStatus = (data: Plugin_Load_Type_Enum) => {
    console.log('load data', data);
    switch (data) {
      case Plugin_Load_Type_Enum.Loaded:
      case Plugin_Load_Type_Enum.Installed:
        return <Tag color={'green'}>{data}</Tag>;
      default:
        return <Tag>{data}</Tag>;
    }
  };

  const getPluginTypeStatus = (data: Plugin_Type_Enum) => {
    switch (data) {
      case Plugin_Type_Enum.Storage:
        return <Tag color={'green'}>{capitalize(data)}</Tag>;
      case Plugin_Type_Enum.Function:
        return <Tag color={'red'}>{capitalize(data)}</Tag>;
      default:
        return <Tag color={'cyan'}>{capitalize(data)}</Tag>;
    }
  };

  const [upsertPluginMutation, { loading: activationLoading }] = useUpsertPluginDetailsMutation({
    onError: ({ graphQLErrors, networkError }) => {
      gqlErrorMessage(graphQLErrors, networkError);
    },
    onCompleted: (data) => {
      successMessage(`${data?.upsertPlugin?.id} plugin updated`);
    },
  });
  const onActivateClick = async (plugin: PluginDetailsFields) => {
    await upsertPluginMutation({
      variables: {
        id: plugin.id,
        system_type: pluginType,
        activate_status: plugin.activate_status,
      },
    });
  };

  return (
    <Card
      title={plugin.title}
      hoverable
      //className={`${styles.addonCard} ${className}`}
      //   extra={hasOption ? getDropDownMenu(item) : null}
      //headStyle={{ border: 'none', position: 'absolute', width: '100%' }}
      extra={[
        <Space>
          {getLoadStatus(plugin.load_status)}
          {getPluginTypeStatus(plugin?.type)}
        </Space>,
      ]}
      actions={[
        <PluginUploadBtn pluginDetails={plugin} />,
        <Button
          //className={`${styles.cardBtn} ${styles.primaryCardBtn}`}
          //style={{ margin: '0 auto', display: 'block' }}
          //onClick={() => onBtnClick(plugin)}
          onClick={() => showDrawer(plugin)}
          disabled={plugin?.env_vars?.length <= 0}
        >
          {'Configure'}
        </Button>,
        /*<Button
          //className={`${styles.cardBtn} ${styles.primaryCardBtn}`}
          style={
            plugin.activate_status === Plugin_Activation_Type_Enum.Deactivated
              ? { backgroundColor: 'green', color: 'white', borderColor: 'green' }
              : { backgroundColor: 'red', color: 'white' }
          }
          loading={activationLoading}
          onClick={() => onActivateClick(plugin)}
        >
          {plugin.activate_status === Plugin_Activation_Type_Enum.Activated
            ? 'Deactivate'
            : 'Active'}
        </Button>,*/
        <Switch
          //className={`${styles.cardBtn} ${styles.primaryCardBtn}`}
          size="default"
          checkedChildren={'Active'}
          unCheckedChildren={'Deactivated'}
          loading={activationLoading}
          onClick={() => onActivateClick(plugin)}
          defaultChecked={plugin.activate_status === Plugin_Activation_Type_Enum.Activated}
        />,
      ]}
    >
      <div className={styles.metaWrapper}>
        <Meta
          avatar={
            <img
              style={{ maxWidth: '120px', margin: '0 auto', padding: '15px' }}
              src={plugin.icon}
              alt="logo"
            />
          }
          title={<span>{plugin.id}</span>}
          description={<span>{plugin.description}</span>}
        />
      </div>
    </Card>
  );
};

export default PluginCard;
