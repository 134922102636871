import { Button, Form, Spin } from 'antd';
import { useGetProjectDriverInfoQuery, useUpdateProjectMutation } from 'generated/graphql';
import { successMessage } from 'utils/message';
import { useEffect, useState } from 'react';
import styles from '../../../components/forms/form.module.scss';
import ProjectDriverForm from 'components/forms/ProjectDriverChoice';

type ProjectUpdateFormType = {
  activeProjectId: string;
  setSettingsDrawerOpen: (val: boolean) => void;
};

export type ProjectCreateOrUpdateFormData = {
  access_key: string;
  db?: string;
  host: string;
  password: string;
  port: string;
  secret_key: string;
  user: string;
  engine: string;
};

const PostUpdateFormContainer = ({
  activeProjectId,
  setSettingsDrawerOpen,
}: ProjectUpdateFormType) => {
  const [form] = Form.useForm();

  const [payload, setPayload] = useState<{ name: string }>();

  const { data: projectDriverInfo, loading } = useGetProjectDriverInfoQuery({
    variables: { id: activeProjectId },
  });

  const [updateProject, { loading: updateLoading }] = useUpdateProjectMutation({
    onCompleted: (data) => {
      setSettingsDrawerOpen(false);
      successMessage('Project updated successfully.');
    },
  });

  const handleSubmit = (data) => {
    updateProject({ variables: { ...data, _id: activeProjectId } });
  };

  const validateMessages = {
    // eslint-disable-next-line no-template-curly-in-string
    required: '${label} is required!',
  };

  const onValuesChange = async (values) => {
    if (values?.name && payload?.name !== values?.name) {
      setPayload({
        ...payload,
        ...values,
      });
    } else if (values?.name === '') {
      form.setFieldsValue({
        id: '',
      });
      setPayload({ ...payload, ...values });
    } else {
      setPayload({ ...payload, ...values });
    }
  };

  /*const onFinish = async (values) => {
    if (values) {
      const final = {
        ...values,
      };
      console.log(values);
    }
  };*/

  useEffect(() => {
    form.setFieldsValue({
      form: projectDriverInfo?.getProject,
    });
  }, [form, projectDriverInfo]);

  return (
    <Spin spinning={loading}>
      <Form
        name="new-project"
        layout="vertical"
        onValuesChange={onValuesChange}
        //onFinish={onFinish}
        validateMessages={validateMessages}
        initialValues={{ remember: false }}
        style={{ height: '100%' }}
        form={form}
        requiredMark={false}
      >
        <ProjectDriverForm projectData={projectDriverInfo?.getProject} mode="update" />

        <div className={styles.buttons}>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            loading={updateLoading}
            onClick={() => handleSubmit}
          >
            Update
          </Button>
        </div>
      </Form>
    </Spin>
  );
};

export default PostUpdateFormContainer;
