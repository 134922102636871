import { useEffect, useState } from 'react';
import { Button, Form, Input, PageHeader, Skeleton } from 'antd';

import styles from './accounts.module.scss';
import { errorMessage, successMessage } from 'utils/message';
import { useAuthContext } from 'hooks/useAuth';
import { TokenData } from '../../../dto/project-list.dto';
import { httpService } from '../../../utils/httpService';
import { CHANGE_PASSWORD, USER_PROFILE } from '../../../configs';
import {
  SystemUserInfo,
  useGetLoggedInUserQuery,
  useUpdateUserProfileMutation,
} from '../../../generated/graphql';

export const AccountSetting = () => {
  const { decodeTokenData } = useAuthContext();
  const tokenData = decodeTokenData();
  // const { loading, error, data } = useQuery(GET_CURRENT_PROJECT)
  // if (loading) return <Loader />
  // if (error) return errorMessage(error);
  // if (data) return <GeneralSetting currentProject={data.currentProject} />
  return tokenData ? <Accounts tokenData={tokenData} /> : <Accounts />;
};

type AccountsProps = {
  profileData?: SystemUserInfo | false;
  tokenData?: TokenData;
};

const Accounts = ({ tokenData }: AccountsProps) => {
  const [changedPasswords, setPasswordChangeChangedPasswords] = useState({});
  const [passwordFormUpdateLoading, setPasswordFormUpdateLoading] = useState<boolean>(false);

  const [profileData, setProfileData] = useState<SystemUserInfo | null>(null);
  const [profileDataLoading, setProfileDataLoading] = useState<boolean>(true);

  /*
  const [updateProfile, { loading: profileUpdateLoading }] = useUpdateUserProfileMutation({
    onCompleted: (data) => {
      // console.log('Log: GeneralSetting -> data', data)
      successMessage('Profile updated successfully.');
    },
  });*/

  /*const { data: profileData, loading: profileDataLoading } = useGetLoggedInUserQuery();

  const { getLoggedInUser } = profileData || {};
  */

  const updateProfile = (_updateData) => {
    httpService
      .post(USER_PROFILE, _updateData)
      .then((res) => {
        const resp = res?.data || {};
        successMessage(resp?.message);
        setProfileData(resp?.body);
        setProfileDataLoading(false);
      })
      .catch((err) => {
        errorMessage(err?.response?.data?.message);
        setProfileDataLoading(false);
      });
  };

  const fetchProfileData = () => {
    httpService
      .get(USER_PROFILE)
      .then((res) => {
        const resp = res?.data || {};
        setProfileData(resp?.body);
        setProfileDataLoading(false);
      })
      .catch((err) => {
        errorMessage(err?.response?.data?.message);
        setProfileDataLoading(false);
      });
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  const changePassword = (payload) => {
    httpService
      .post(CHANGE_PASSWORD, {
        ...payload,
      })
      .then((res) => {
        const resp = res?.data || {};
        successMessage(resp?.message);
        setPasswordFormUpdateLoading(false);
      })
      .catch((err) => {
        errorMessage(err?.response?.data?.message);
        setPasswordFormUpdateLoading(false);
      });
  };

  const onProfileUpdateValuesChange = (values) => {
    setProfileData({ ...profileData, ...values });
  };

  const onProfileUpdateFinish = (values) => {
    if (Object.keys(profileData)?.length > 0) {
      setProfileDataLoading(true);
      updateProfile(profileData);
    }
  };

  const onPasswordUpdateFinish = (values) => {
    if (Object.keys(changedPasswords)?.length > 0) {
      setPasswordFormUpdateLoading(true);
      changePassword(changedPasswords);
    }
  };

  const onPasswordUpdateValuesChange = (values) => {
    setPasswordChangeChangedPasswords({ ...changedPasswords, ...values });
  };

  return (
    <div className={styles.root}>
      <Form
        name="profile-form"
        layout="vertical"
        onValuesChange={onProfileUpdateValuesChange}
        onFinish={onProfileUpdateFinish}
        initialValues={{ remember: true }}
      >
        <PageHeader
          title="Profile"
          subTitle="Profile Settings"
          extra={[
            <Button type="primary" htmlType="submit" loading={profileDataLoading}>
              UPDATE PROFILE
            </Button>,
          ]}
        >
          {profileDataLoading ? (
            <Skeleton />
          ) : (
            <>
              <Form.Item
                name="email"
                label="Email"
                initialValue={profileData ? profileData?.email : ''}
                rules={[
                  {
                    required: true,
                    message: 'Email is Required',
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                name="first_name"
                label="First Name"
                rules={[
                  {
                    required: true,
                    message: 'First Name is Required',
                  },
                ]}
                initialValue={profileData ? profileData?.first_name : ''}
              >
                <Input placeholder={'Your first name'} />
              </Form.Item>
              <Form.Item
                name="last_name"
                label="Last Name"
                initialValue={profileData ? profileData?.last_name : ''}
              >
                <Input placeholder={'Your last name'} />
              </Form.Item>
              {/*              <Form.Item
                name="project_limit"
                label="Project Limit"
                initialValue={profileData ? profileData?.project_limit : ''}
              >
                <Input disabled />
              </Form.Item>*/}
            </>
          )}
        </PageHeader>
      </Form>
      <Form
        name="password-form"
        layout="vertical"
        onValuesChange={onPasswordUpdateValuesChange}
        onFinish={onPasswordUpdateFinish}
        initialValues={{ remember: true }}
      >
        <PageHeader
          title="Password"
          subTitle="Update Password"
          extra={[
            <Button type="primary" htmlType="submit" loading={passwordFormUpdateLoading}>
              CHANGE PASSWORD
            </Button>,
          ]}
        >
          <Form.Item
            name="old_password"
            label="Old Password"
            rules={[
              {
                required: true,
                message: 'Old Password Required',
              },
            ]}
          >
            <Input type="password" />
          </Form.Item>

          <Form.Item
            name="new_password"
            label="New Password"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'New Password Required',
              },
            ]}
          >
            <Input type="password" />
          </Form.Item>
          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={['new_password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new_password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('The new password that you entered do not match!')
                  );
                },
              }),
            ]}
          >
            <Input type="password" />
          </Form.Item>
        </PageHeader>
      </Form>
    </div>
  );
};

export default Accounts;
