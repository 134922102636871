import { Avatar, Dropdown, List, MenuProps, Tag } from 'antd';
import { DeleteOutlined, EllipsisOutlined, UserOutlined } from '@ant-design/icons';

import { GetSettingsTeamsMembersQuery, useUpdateSettingTeamsMutation } from 'generated/graphql';
import { successMessage } from 'utils/message';
import { GET_TEAMS_MEMBERS } from '../../../../graphql/queries/settings';
import React from 'react';

type TeamMembersListProps = {
  teamMembersData: GetSettingsTeamsMembersQuery;
};

const TeamMembersList: React.FC<TeamMembersListProps> = ({ teamMembersData: { teamMembers } }) => {
  const [removeMemberByID] = useUpdateSettingTeamsMutation({
    onCompleted: () => {
      successMessage('Team Member Removed Successfully!');
    },
  });

  const removeMember = async (id) => {
    await removeMemberByID({
      variables: {
        remove_team_member: { member_id: id },
      },
      refetchQueries: [GET_TEAMS_MEMBERS],
    });
  };

  function ListAction({ id }) {
    const items: MenuProps['items'] = [
      {
        key: '1',
        label: 'Remove Member',
        onClick: () => {
          removeMember(id);
        },
        icon: <DeleteOutlined />,
      },
    ];

    return (
      <Dropdown menu={{ items }}>
        <EllipsisOutlined rotate={90} style={{ fontSize: '24px' }} />
      </Dropdown>
    );
  }

  return (
    <List
      itemLayout="horizontal"
      dataSource={teamMembers}
      renderItem={(item) => (
        <List.Item
          extra={<ListAction id={item.id} />}
          actions={[
            <div>
              {item?.project_access_permissions.map((e, index) => (
                <Tag color="blue" style={{ textTransform: 'capitalize' }} key={index}>
                  {e}
                </Tag>
              ))}
            </div>,
          ]}
        >
          <List.Item.Meta
            avatar={<Avatar src={item?.avatar} icon={<UserOutlined />} />}
            title={item.email || ''}
            description={item.project_assigned_role || ''}
          />
        </List.Item>
      )}
    />
  );
};
export default TeamMembersList;
