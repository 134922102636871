import {
  ApiOutlined,
  DatabaseOutlined,
  KeyOutlined,
  LockOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { Alert, Form, Input, Select } from 'antd';
import styles from './form.module.scss';
import { ProjectModel } from 'generated/graphql';
import { useEffect, useState } from 'react';
import { DBLIST } from 'data/dbList';

const { Option } = Select;

type ProjectDriverChoiceProps = {
  projectData?: ProjectModel;
  mode: 'create' | 'update';
};

const ProjectDriverChoice = ({ projectData, mode }: ProjectDriverChoiceProps) => {
  const driver = projectData?.driver;

  const [selectedEngine, setSelectedEngine] = useState<string>('');

  useEffect(() => {
    setSelectedEngine(driver?.engine || '');
  }, [driver?.engine]);

  const renderInput = (dbList) => {
    return dbList?.inputList?.map((input) => {
      switch (input) {
        case 'cloud':
          return (
            <Alert
              message={`${dbList.title} is a Managed NoSQL Database Hosted by Apito. It's free of Charge`}
              type="success"
            />
          );
        case 'soon':
          return <Alert message={`Support for ${dbList.title} is coming soon`} type="success" />;
        case 'paid':
          return (
            <Alert
              message={`Support for ${dbList.title} is available in Custom Enterprise Version Only. Contact Support for more information`}
              type="info"
            />
          );
        case 'info':
          return (
            <Alert
              style={{ marginBottom: '1rem' }}
              message={`Enter Your Self hosted ${dbList.title} Connection Information`}
              type="info"
            />
          );
        case 'user':
          return (
            <Form.Item
              name={['driver', 'user']}
              label="User"
              rules={[
                {
                  required: true,
                  type: 'string',
                  message: 'User is a required field.',
                },
              ]}
            >
              <Input
                placeholder="User"
                type="text"
                prefix={<UserOutlined />}
                className={styles.input}
              />
            </Form.Item>
          );
        case 'password':
          return (
            <Form.Item
              name={['driver', 'password']}
              label="Password"
              rules={[
                {
                  required: true,
                  type: 'string',
                },
              ]}
            >
              <Input
                placeholder="Password"
                type="password"
                prefix={<KeyOutlined />}
                className={styles.input}
              />
            </Form.Item>
          );
        case 'db':
          return (
            <Form.Item
              name={['driver', 'database']}
              label="Database"
              rules={[
                {
                  required: true,
                  type: 'string',
                },
              ]}
            >
              <Input
                placeholder="Database"
                type="text"
                prefix={<DatabaseOutlined />}
                className={styles.input}
                disabled={mode === 'update'}
              />
            </Form.Item>
          );
        case 'host':
          return (
            <Form.Item
              name={['driver', 'host']}
              label="Host"
              rules={[
                {
                  required: true,
                  type: 'string',
                },
              ]}
            >
              <Input
                placeholder="Host"
                type="text"
                prefix={<UserSwitchOutlined />}
                className={styles.input}
              />
            </Form.Item>
          );
        case 'port':
          return (
            <Form.Item
              name={['driver', 'port']}
              label="Port"
              rules={[
                {
                  required: true,
                  type: 'string',
                },
              ]}
            >
              <Input
                placeholder="Port"
                type="text"
                prefix={<ApiOutlined />}
                className={styles.input}
              />
            </Form.Item>
          );
        case 'access_key':
          return (
            <Form.Item
              name={['driver', 'access_key']}
              label="Access Key"
              rules={[
                {
                  required: true,
                  type: 'string',
                },
              ]}
            >
              <Input
                placeholder="Access Key"
                type="text"
                prefix={<KeyOutlined />}
                className={styles.input}
              />
            </Form.Item>
          );
        case 'secret_key':
          return (
            <Form.Item
              name={['driver', 'secret_key']}
              label="Secret Key"
              rules={[
                {
                  required: true,
                  type: 'string',
                },
              ]}
            >
              <Input
                placeholder="Secret Key"
                type="text"
                prefix={<LockOutlined />}
                className={styles.input}
              />
            </Form.Item>
          );
        default:
          return '';
      }
    });
  };

  return (
    <>
      <Form.Item
        name={['driver', 'engine']}
        label="Database Engine"
        // rules={[
        //   {
        //     required: true,
        //     type: 'string',
        //   },
        // ]}
      >
        <Select
          className={styles.select}
          placeholder="Select A Engine"
          onChange={(value) => {
            setSelectedEngine(value);
          }}
          disabled={mode === 'update'}
          defaultValue={'apito'}
        >
          {DBLIST.map((db) => (
            <Option value={db.value} key={db.value}>
              {db.title}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <div style={{ marginBottom: '20px' }}>
        {renderInput(DBLIST.find((db) => db.value === selectedEngine))}
      </div>
    </>
  );
};
export default ProjectDriverChoice;
