import LogicLayout from 'components/layouts/LogicLayout';
import { FunctionContext, FunctionReducer } from 'context/FunctionContext';
import { CloudFunctionType, useGetAllFunctionInfoQuery } from 'generated/graphql';
import { useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import CreateFunction from './CreateLogic';
import FunctionForm from './FunctionForm';
import { Avatar, Tabs } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';

// import { FunctionSider } from './FunctionSider';
// import FunctionForm from './FunctionForm';
// import CreateFunction from './CreateFunction';

// // Functions Management
const LogicContainer = () => {
  const [state, dispatch] = useReducer(FunctionReducer, { target: '' });
  const [isCreate, setIsCreate] = useState(false);
  const { data: funcList } = useGetAllFunctionInfoQuery();

  const params = useParams();

  // set ID for Second column's data-fetching
  const [functionData, setFunctionData] = useState<CloudFunctionType>(null);

  useEffect(() => {
    if (params?.fnName) {
      const item = funcList?.projectFunctionsInfo?.find((item) => item.name === params?.fnName);
      setFunctionData(item);
    }
  }, [funcList?.projectFunctionsInfo, params?.fnName]);

  return (
    <FunctionContext.Provider value={{ state, dispatch }}>
      <LogicLayout
        setIsCreate={setIsCreate}
        setFunctionData={setFunctionData}
        selectedFunction={functionData?.name}
        funcList={funcList}
      >
        {/* <LogicLayout setFunctionData={setFunctionData} setIsCreate={setIsCreate}> */}
        {/* <FunctionSider setFunctionData={setFunctionData} setIsCreate={setIsCreate} /> */}
        {!isCreate && functionData !== null ? (
          <FunctionForm functionData={functionData} />
        ) : (
          <CreateFunction />
        )}
        {/* </LogicLayout> */}
      </LogicLayout>
    </FunctionContext.Provider>
  );
};

export default LogicContainer;
