import React, { useState } from 'react';
import { Button, Form, Input, Select, Switch } from 'antd';
import { ProjectModel } from 'generated/graphql';
import localsData from 'data/setting/locals.json';

const { Option } = Select;

type GeneralFormProps = {
  project: ProjectModel;
  onFinish: (value: Record<string, never>) => void;
  submissionLoading: boolean;
};

const GeneralForm: React.FC<GeneralFormProps> = ({ project, onFinish, submissionLoading }) => {
  const [payload, setPayload] = useState({});
  const localsEntries = Object.entries(localsData);

  const onValuesChange = (values) => {
    setPayload({ ...payload, ...values });
  };

  return (
    <Form
      name="general-setting"
      layout="vertical"
      onValuesChange={onValuesChange}
      onFinish={onFinish}
      initialValues={{ remember: true }}
    >
      <Form.Item name="id" label="Project ID" initialValue={project?.id}>
        <Input readOnly disabled />
      </Form.Item>
      <Form.Item
        name="name"
        label="Project Name"
        initialValue={project?.name}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="description"
        label="Project Description"
        initialValue={project?.description}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Content Languages">
        <Form.Item
          name={['settings', 'locals']}
          initialValue={project.settings?.locals}
          style={{ marginTop: '5px', marginBottom: 5 }}
        >
          <Select
            showSearch
            mode="multiple"
            placeholder="Select Local"
            optionFilterProp="children"
            style={{ width: '100%' }}
          >
            {localsEntries.length > 0
              ? localsEntries.map((d, i) => (
                  <Option key={i} label={d[0]} value={d[0]}>
                    {d[1]}
                  </Option>
                ))
              : null}
          </Select>
        </Form.Item>
      </Form.Item>
      <Form.Item
        initialValue={project?.settings?.enable_revision_history}
        name={['settings', 'enable_revision_history']}
        label="Enable Document History"
        valuePropName="checked"
      >
        <Switch disabled={true} />
      </Form.Item>
      <Form.Item
        initialValue={project?.settings?.system_graphql_hooks}
        name={['settings', 'system_graphql_hooks']}
        label="Enable System Graphql Hooks"
        valuePropName="checked"
      >
        <Switch disabled={true} />
      </Form.Item>
      <Button type="primary" htmlType="submit" loading={submissionLoading}>
        SAVE
      </Button>
    </Form>
  );
};

export default GeneralForm;
