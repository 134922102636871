import React from 'react';
import Sync from './Sync';

const SyncContainer = () => {
  return (
    <div>
      <Sync />
    </div>
  );
};

export default SyncContainer;
