import { useEffect, useState } from 'react';
import { Card, Drawer, PageHeader, Tag } from 'antd';
import { httpService } from 'utils/httpService';
import { USER_PROFILE, USER_SYNC_TOKEN } from 'configs';
import { errorMessage } from 'utils/message';
import Loader from 'components/loader/Loader';
// import { DrawerCloseComponent } from 'components/custom';

// import SupportDrawerComponent from './SupportDrawerComponent';

// export function GetSupportToken() {
//   return <Audit />;
// }

const Sync = () => {
  const [syncToken, setSyncToken] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const fetchSyncData = () => {
    httpService
      .post(USER_SYNC_TOKEN, {})
      .then((res) => {
        const resp = res?.data || {};
        setSyncToken(resp?.token);
        setLoading(false);
      })
      .catch((err) => {
        errorMessage(err?.response?.data?.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchSyncData();
  }, []);

  return (
    <PageHeader title="Cloud Sync" subTitle="Publish Local Project to Cloud">
      <Card style={{ textAlign: 'center' }}>
        <p>Cloud Sync Token</p>
        {loading ? <Loader /> : <Tag>{syncToken}</Tag>}
      </Card>
    </PageHeader>
  );
};

export default Sync;
