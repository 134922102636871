import { gql } from '@apollo/client';

export const GET_FORM_GENERATION_DATA = gql`
  query getFormGenerationData($model_name: String) {
    projectModelsInfo(model_name: $model_name) {
      name
      locals
      single_page
      single_page_uuid
      system_generated
      fields {
        serial
        identifier
        label
        input_type
        field_type
        field_sub_type
        description
        system_generated
        sub_field_info {
          description
          field_type
          field_sub_type
          identifier
          input_type
          label
          serial
          system_generated
          parent_field
          validation {
            as_title
            char_limit
            double_range_limit
            fixed_list_elements
            hide
            is_email
            is_gallery
            is_multi_choice
            int_range_limit
            list_type
            locals
            placeholder
            required
            unique
          }
        }
        validation {
          required
          locals
          is_multi_choice
          int_range_limit
          hide
          fixed_list_elements
          double_range_limit
          char_limit
          as_title
          is_email
          unique
          placeholder
          is_gallery
          is_password
        }
      }
      connections {
        model
        relation
        type
        known_as
      }
    }
  }
`;

export const UPDATE_CONTENT_FORM = gql`
  mutation updateContentForm(
    $_id: String!
    $single_page_data: Boolean
    $model_name: String!
    $local: String
    $payload: Map
    $connect: Map
    $disconnect: Map
    $status: String
  ) {
    upsertModelData(
      _id: $_id
      single_page_data: $single_page_data
      model_name: $model_name
      local: $local
      payload: $payload
      connect: $connect
      disconnect: $disconnect
      status: $status
    ) {
      _key
      data
      type
      id
      meta {
        created_at
        status
        updated_at
        last_modified_by {
          id
          first_name
        }
      }
    }
  }
`;

export const GET_CONTENT_FORM_GENERATION_DATA = gql`
  query getContentFormGenerationData($model_name: String) {
    projectModelsInfo(model_name: $model_name) {
      name
      locals
      fields {
        serial
        identifier
        label
        input_type
        field_type
        field_sub_type
        description
        system_generated
        sub_field_info {
          description
          field_type
          field_sub_type
          identifier
          input_type
          label
          serial
          system_generated
          parent_field
          validation {
            as_title
            char_limit
            double_range_limit
            fixed_list_elements
            hide
            is_email
            is_gallery
            is_multi_choice
            int_range_limit
            list_type
            locals
            placeholder
            required
            unique
          }
        }
        validation {
          required
          locals
          is_multi_choice
          int_range_limit
          hide
          fixed_list_elements
          double_range_limit
          char_limit
          as_title
          is_email
          unique
          placeholder
          is_gallery
          is_password
        }
      }
      connections {
        model
        relation
        type
        known_as
      }
    }
  }
`;

export const GET_SELECTED_MODEL_TABLE_LIST_DETAILED = gql`
  query getSelectedModeTablelListDetailed(
    $model: String!
    $limit: Int
    $page: Int
    $search: String
    $where: Map
    $connection: ListAllDataDetailedOfAModelConnectionPayload
    $intersect: Boolean
  ) {
    getModelData(
      model: $model
      limit: $limit
      page: $page
      search: $search
      where: $where
      connection: $connection
      intersect: $intersect
      status: all
    ) {
      results {
        relation_doc_id
        id
        data
        meta {
          created_at
          updated_at
          status
        }
      }
      count
    }
  }
`;

export const DELETE_MODEL_DATA = gql`
  mutation deleteModelData($model_name: String!, $_id: String) {
    deleteModelData(model_name: $model_name, _id: $_id) {
      id
    }
  }
`;

// for content table data
export const GET_SELECTED_MODEL_CONTENT_LIST_DETAILED = gql`
  query getSelectedModelContentListDetailed(
    $model: String!
    $limit: Int
    $page: Int
    $search: String
    $where: Map
    $connection: ListAllDataDetailedOfAModelConnectionPayload
    $intersect: Boolean
  ) {
    getModelData(
      model: $model
      limit: $limit
      page: $page
      search: $search
      where: $where
      connection: $connection
      intersect: $intersect
    ) {
      results {
        id
        data
        meta {
          created_at
          updated_at
          status
        }
      }
      count
    }
  }
`;

export const FORM_DATA_QUERY_REVISION = gql`
  query FormDataQueryRevision($_id: String!, $model: String) {
    listSingleModelRevisionData(_id: $_id, model: $model) {
      status
      revision_at
      id
    }
  }
`;

export const GET_FORM_DATA = gql`
  query GetFormData(
    $_id: String!
    $model: String
    $local: String
    $revision: Boolean
    $single_page_data: Boolean
  ) {
    getSingleData(
      _id: $_id
      model: $model
      local: $local
      revision: $revision
      single_page_data: $single_page_data
    ) {
      _key
      data
      id
      type
      meta {
        status
        updated_at
        created_at
        created_by {
          avatar
          id
          first_name
          role
          email
        }
        last_modified_by {
          id
          first_name
          role
          email
          avatar
        }
      }
    }
  }
`;

export const CREATE_MODEL_DATA = gql`
  mutation createModelData(
    $model_name: String!
    $local: String
    $payload: Map
    $connect: Map
    $disconnect: Map
    $status: String
    $faker: Boolean
  ) {
    upsertModelData(
      model_name: $model_name
      local: $local
      payload: $payload
      connect: $connect
      disconnect: $disconnect
      status: $status
      faker: $faker
    ) {
      _key
      data
      type
      id
      meta {
        created_at
        status
        updated_at
        last_modified_by {
          id
          first_name
        }
      }
    }
  }
`;
