import { Col, Row, Skeleton, Typography } from 'antd';
import MainLayout from 'components/layouts/MainLayout';
import {
  useGetPermissionsAndScopesQuery,
  useGetSettingsTeamsMembersQuery,
  useGetSettingsTeamsRolesQuery,
} from 'generated/graphql';
import TeamMembersList from './TeamMembersList';
import TeamsForm from './TeamsForm';

const { Text, Title } = Typography;

const TeamsContainer = () => {
  const { data: rolesData, loading: rolesLoading } = useGetSettingsTeamsRolesQuery();
  const { data: roleScopeData, loading: roleScopeLoading } = useGetPermissionsAndScopesQuery();
  const {
    data: teamMembersData,
    loading: teamMembersLoading,
    fetchMore,
  } = useGetSettingsTeamsMembersQuery();

  return (
    <MainLayout
      title="Teams"
      subtitle="Invite Your Teams To Collaborate On Your Project"
      breadcrumList={['Setting', 'Teams']}
    >
      <Row>
        <TeamsForm
          roles={rolesData?.currentProject?.roles}
          rolesLoading={rolesLoading}
          permissions={roleScopeData?.listPermissionsAndScopes?.permissions}
          roleScopeLoading={roleScopeLoading}
          fetchMore={fetchMore}
        />
      </Row>

      <Row>
        <Col span={24} lg={{ span: 24 }}>
          <Title level={4}>
            <Text style={{ fontSize: '16px' }}>Existing Team Members</Text>
          </Title>
          {teamMembersLoading ? (
            <Skeleton active />
          ) : (
            <TeamMembersList teamMembersData={teamMembersData} />
          )}
        </Col>
      </Row>
    </MainLayout>
  );
};

export default TeamsContainer;
