import CheckboxTooltip from 'components/wrapper/CheckboxTooltip';
import styles from '../modelContainer/modelContainer.module.scss';
import React from 'react';
import { Checkbox, Form, Space } from 'antd';
import { capitalize } from 'lodash';

type RenderCheckboxProps = {
  checkboxList?: string[];
  field_type?: string;
  validation?: { locals: string; fixed_list_elements: string[]; placeholder: string };
  setIs_multi_choise?: Function;
};

const RenderCheckbox = ({
  checkboxList,
  field_type,
  validation,
  setIs_multi_choise,
}: RenderCheckboxProps) => {
  const _replace = (str) => str.replace(/_/g, ' ');

  const onMultiChange = (e, itemName) => {
    if (itemName === 'is_multi_choice') setIs_multi_choise(e.target.checked);
  };

  return (
    <>
      {checkboxList.map((item, i) => {
        if (item === 'as_title' && field_type === 'text') {
          return (
            <Form.Item
              className={styles.checkbox}
              name={['validation', `${item}`]}
              valuePropName="checked"
              initialValue={validation?.[item] ?? false}
            >
              <Checkbox>
                <Space>
                  Show In Preview
                  <CheckboxTooltip field={item} key={i} />
                </Space>
              </Checkbox>
            </Form.Item>
          );
        }
        if (item === 'is_email' && field_type === 'email') {
          return (
            <Form.Item
              className={styles.checkbox}
              name={['validation', `${item}`]}
              valuePropName="checked"
              initialValue={validation?.[item] ?? false}
            >
              <Checkbox>
                <Space>
                  {capitalize(_replace(item))}
                  <CheckboxTooltip field={item} key={i} />
                </Space>
              </Checkbox>
            </Form.Item>
          );
        }
        if (item === 'is_multi_choice' && field_type === 'list') {
          return (
            <Form.Item
              className={styles.checkbox}
              name={['validation', `${item}`]}
              valuePropName="checked"
              initialValue={validation?.[item] ?? false}
            >
              <Checkbox
                onChange={(e) => {
                  onMultiChange(e, item);
                }}
              >
                <Space>
                  {capitalize(_replace(item))}
                  <CheckboxTooltip key={i} field={item} style={{ display: 'none' }} />
                </Space>
              </Checkbox>
            </Form.Item>
          );
        }
        if (item === 'required') {
          return (
            <Form.Item
              name={['validation', `${item}`]}
              valuePropName="checked"
              initialValue={validation?.[item] ?? false}
            >
              <Checkbox>
                <Space>
                  {capitalize(_replace(item))}
                  <CheckboxTooltip key={i} field={item} />
                </Space>
              </Checkbox>
            </Form.Item>
          );
        }
        if (item === 'hide' && field_type !== 'media') {
          return (
            <Form.Item
              className={styles.checkbox}
              name={['validation', `${item}`]}
              valuePropName="checked"
              initialValue={validation?.[item] ?? false}
            >
              <Checkbox>
                <Space>
                  {capitalize(_replace(item))}
                  <CheckboxTooltip key={i} field={item} />
                </Space>
              </Checkbox>
            </Form.Item>
          );
        }
        if (
          item === 'unique' &&
          field_type !== 'media' &&
          field_type !== 'list' &&
          field_type !== 'boolean' &&
          field_type !== 'geo'
        ) {
          return (
            <Form.Item
              className={styles.checkbox}
              name={['validation', `${item}`]}
              valuePropName="checked"
              initialValue={validation?.[item] ?? false}
            >
              <Checkbox>
                <Space>
                  {capitalize(_replace(item))}
                  <CheckboxTooltip key={i} field={item} />
                </Space>
              </Checkbox>
            </Form.Item>
          );
        }
        if (item === 'is_gallery' && field_type === 'media') {
          return (
            <Form.Item
              className={styles.checkbox}
              name={['validation', `${item}`]}
              valuePropName="checked"
              initialValue={validation?.[item] ?? false}
            >
              <Checkbox>
                <Space>
                  Allow Multiple Media Upload
                  <CheckboxTooltip key={i} field={item} />
                </Space>
              </Checkbox>
            </Form.Item>
          );
        }
        if (item === 'is_url' && field_type === 'media') {
          return (
            <Form.Item
              className={styles.checkbox}
              name={['validation', `${item}`]}
              valuePropName="checked"
              initialValue={validation?.[item] ?? false}
            >
              <Checkbox>
                <Space>
                  Allow Media URL
                  <CheckboxTooltip key={i} field={item} />
                </Space>
              </Checkbox>
            </Form.Item>
          );
        }
        return null;
      })}
    </>
  );
};

export default RenderCheckbox;
