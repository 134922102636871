import { Form, Select } from 'antd';
import styles from './form.module.scss';
import { ProjectModel } from 'generated/graphql';
import { useEffect, useState } from 'react';
import { PLAN_LIST } from 'constants/plansData';
import { PlanData } from 'dto/plan';

const { Option } = Select;

type ProjectPlanChoiceProps = {
  projectData?: ProjectModel;
  mode: 'create' | 'update';
  callback: (plan: PlanData) => void;
};

const ProjectPlanChoice = ({ projectData, mode, callback }: ProjectPlanChoiceProps) => {
  //const plan = projectData?.project_plan;

  /*   useEffect(() => {
    setSelectedPlan(plan || PLAN_LIST[0]);
  }, [plan]); */

  return (
    <>
      <Form.Item
        name={['project_plan']}
        label="Project Plan"
        // rules={[
        //   {
        //     required: true,
        //     type: 'string',
        //   },
        // ]}
      >
        <Select
          className={styles.select}
          placeholder="Select A Plan"
          onChange={(value) => {
            callback(PLAN_LIST.find((plan) => plan.title === value) || PLAN_LIST[0]);
          }}
          disabled={mode === 'update'}
          defaultValue={'Developer'}
        >
          {PLAN_LIST.slice(0, 3).map((plan) => (
            <Option value={plan.title} key={plan.key}>
              {plan.title}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};
export default ProjectPlanChoice;
