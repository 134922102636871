import { useState } from 'react';
import { Button, Checkbox, Form, Input, Typography } from 'antd';

// import AddRolesTable from './AddRolesTable';
import {
  GetSettingsTeamsRolesQuery,
  ListPermissionsAndScopesResponse,
  useUpsertRoleToProjectMutation,
} from 'generated/graphql';
import { successMessage } from 'utils/message';
import GroupedCheckBoxWithAllSelect from 'components/inputs/GroupedCheckBoxWithAllSelect';
import AddRolesTable from './AddRolesTable';
import { GET_ROLES } from '../../../../../graphql/queries/settings';

const { Title } = Typography;

type RolesDrawerProps = {
  rolesData: GetSettingsTeamsRolesQuery;
  rolesScopeData: ListPermissionsAndScopesResponse;
  roleKey: string;
};

const RolesDrawer = ({ rolesData, rolesScopeData, roleKey }: RolesDrawerProps) => {
  const { is_admin, api_permissions, system_generated } =
    rolesData?.currentProject?.roles?.[`${roleKey}`] ?? {};
  const { functions, models } = rolesScopeData;

  const [isSuperAdmin, setIsSuperAdmin] = useState(is_admin || false);
  const [form] = Form.useForm();
  const formValueUpdater = form.setFieldsValue;

  const [addRole, { loading }] = useUpsertRoleToProjectMutation({
    onCompleted: (data) => {
      successMessage(roleKey ? 'Role Updated Successfully!' : 'Role Created Successfully!');
    },
  });

  const onFinish = async (values) => {
    await addRole({
      variables: {
        ...values,
      },
      refetchQueries: [GET_ROLES],
    });
  };

  return (
    <div>
      <Form
        name="webhook"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ remember: true }}
      >
        <div
          className="justify-align-center"
          style={{ justifyContent: 'space-between', display: 'flex' }}
        >
          <Form.Item
            name="name"
            label="Role Name"
            initialValue={roleKey || ''}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Button type="primary" htmlType="submit" disabled={system_generated} loading={loading}>
            {roleKey ? 'UPDATE ROLE' : 'CREATE ROLE'}
          </Button>
        </div>

        <Form.Item name="is_admin" valuePropName="checked" initialValue={isSuperAdmin}>
          <Checkbox onChange={(e) => setIsSuperAdmin(e.target.checked)}>Super Admin</Checkbox>
        </Form.Item>

        {!isSuperAdmin ? (
          <div>
            {functions?.length > 0 ? (
              <GroupedCheckBoxWithAllSelect
                options={functions?.map((item) => ({ label: item, value: item })) || []}
                // initialValue={logic_executions || []}
                formValueUpdater={formValueUpdater}
                name="logic_executions"
                label="Logic Execution"
                isDisabled={system_generated}
              />
            ) : null}

            <Form.Item>
              <div>
                <Title level={5} className="label">
                  API Permission
                </Title>
                <br />
                <AddRolesTable
                  models={models || []}
                  initialData={api_permissions || null}
                  isDisabled={system_generated}
                />
              </div>
            </Form.Item>
          </div>
        ) : null}
      </Form>

      <style>
        {`
            .label {
                font-family: Raleway;
                font-style: normal;
                font-weight: bold !important;
                font-size: 16px !important;
                line-height: 19px !important;
                color: #687074 !important;
            }
        `}
      </style>
    </div>
  );
};

export default RolesDrawer;
