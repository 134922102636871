import { useApolloClient } from '@apollo/client';
import {
  Alert,
  Button,
  Checkbox,
  Descriptions,
  Empty,
  Form,
  Input,
  InputNumber,
  Layout,
  Skeleton,
  Space,
  Spin,
  Tabs,
  Tag,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { flatten } from 'lodash/array';

import { CloudUploadOutlined, EditOutlined } from '@ant-design/icons';
import { DynamicFormList } from 'components/groupedInput/DynamicFormList';
import { BooleanSelector } from 'components/inputs/BooleanSelector';
import { CustomDatePicker } from 'components/inputs/CustomDatePicker';
import FixedListSelector from 'components/inputs/FixedListSelector';
import { ImageUploaderModal } from 'components/inputs/ImageUploaderModal';
import {
  GetFormDataQuery,
  GetFormGenerationDataQuery,
  ModelType,
  useCreateModelDataMutation,
  useGetFormDataLazyQuery,
  useGetFormGenerationDataQuery,
  useUpdateContentFormMutation,
} from 'generated/graphql';
import { capitalize } from 'lodash';
import LoadingBar from 'react-top-loading-bar';
import { generateLabel } from 'utils/GenerateLabelAndIcon';
import { errorMessage, gqlErrorMessage, successMessage } from 'utils/message';
import ReactDraft from 'utils/ReactDraft';
import { SelectContent } from '../../../components/custom/SelectContent';
import { CONNECT_DISCONNECT_QUERY, CONTENT_MEDIA_QUERY } from '../../../localQuery';
import styles from './editContentForm.module.scss';
import { InputGeoField } from './InputGeoField';
import { RepeatedFieldsGenerator } from './RepeatedFieldsGenerator';
import RevisionHistory from './RevisionHistory';

const localsData = require('data/setting/locals.json');

dayjs.extend(relativeTime);

const { Content, Sider } = Layout;
const { TabPane } = Tabs;

const { Text } = Typography;
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const validateMessages = {
  // eslint-disable-next-line no-template-curly-in-string
  required: '${label} is required!',
};

const EditAndCreateContentForm = (props) => {
  const { contentData, formType } = props;
  const client = useApolloClient();

  const { loading, error, data } = useGetFormGenerationDataQuery({
    variables: {
      model_name: `${contentData?.model}`,
    },
    // skip: !contentData?.id,
  });

  // clear cache for create (relation)
  client.cache.writeQuery({
    query: CONNECT_DISCONNECT_QUERY,
    variables: {
      _id: contentData?.id,
    },
    data: {
      connect_ids: {},
      disconnect_ids: {},
    },
  });

  // clear cache for create (mediaUpload)
  client.cache.writeQuery({
    query: CONTENT_MEDIA_QUERY,
    data: { media_data: {} },
  });

  // if (contentSiderList?.listModelData?.length <= 0) return <div style={{ margin: '30vh auto 0 auto' }}><EmptyContent model={target} setIsCreate={setIsCreate} /></div>
  if (!contentData?.id && formType === 'edit') {
    return (
      <div style={{ margin: '35vh auto 0 auto' }}>
        <SelectContent />
      </div>
    );
  }

  if (loading) return <Skeleton active />;

  if (error) {
    errorMessage(error);
    return <Empty />;
  }

  if (data) {
    const modelInfo = data.projectModelsInfo[0];
    return <GetEditContent modelInfo={modelInfo} {...props} />;
  }
  return null;
};

function GetEditContent(props) {
  const { contentData, modelInfo, formType } = props;
  const [formData, setFormData] = useState<GetFormDataQuery['getSingleData'] | undefined>();

  const localsArr =
    modelInfo?.locals?.length > 0 ? Array.from(new Set(['en', ...modelInfo?.locals])) : ['en']; // 'En' First Sorting
  const [localNavKey, setLocalNavKey] = useState(localsArr?.[0]);

  const [getContentByID, { loading, error, data }] = useGetFormDataLazyQuery({
    fetchPolicy: 'network-only',
    onError: ({ graphQLErrors, networkError }) => {
      if (formType === 'edit') {
        gqlErrorMessage(graphQLErrors, networkError);
      }
    },
  });

  useEffect(() => {
    if (formType === 'create') {
      setFormData({});
    } else if (formType === 'edit' && data !== undefined) {
      setFormData(data?.getSingleData);
    }
  }, [data, formType]);

  const onLocalTabChange = (localKey) => {
    setLocalNavKey(localKey);
  };

  useEffect(() => {
    if (contentData?.id !== undefined && localNavKey) {
      getContentByID({
        variables: {
          _id: contentData?.id,
          model: contentData?.model,
          local: localNavKey,
          revision: contentData?.revision,
          single_page_data: contentData?.single_page_data,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localNavKey, contentData?.id]);

  if (error && formType === 'edit') return <Empty />;

  return (
    <div>
      <Tabs
        defaultActiveKey="en"
        type="card"
        size="small"
        style={{ marginTop: 10 }}
        onChange={onLocalTabChange}
      >
        {localsArr.map((local, i) => (
          <TabPane tab={capitalize(localsData?.[`${local}`] || local || '')} key={local} />
        ))}
      </Tabs>
      <Spin spinning={loading}>
        <EditContentFormContainer
          localsArr={localsArr}
          formData={formData}
          local={localNavKey}
          {...props}
        />
      </Spin>
    </div>
  );
}

type EditContentFormContainerProps = {
  localsArr: string[];
  local: string;
  formData: GetFormDataQuery['getSingleData'];
  modelInfo: ModelType;
  onContentEdited: any;
  contentData: any;
  formType: 'edit' | 'create';
  disableInput: false;
  onContentCreated: any;
};

const EditContentFormContainer = ({
  localsArr,
  local,
  formData,
  modelInfo,
  onContentEdited,
  contentData,
  formType,
  disableInput,
  onContentCreated,
}: EditContentFormContainerProps) => {
  const [form] = Form.useForm();

  const client = useApolloClient();

  const [mediaData, setMediaData] = useState({});
  const [connect, setConnect] = useState({});
  const [disconnect, setDisconnect] = useState({});
  const [submitType, setSubmitType] = useState<'publish' | 'draft'>('publish');

  const progress = 0;

  const [formErrors, setFormErrors] = useState([]);

  //useEffect(() => form.resetFields(), [props.initialValues]);

  useEffect(() => {
    form.setFieldsValue({
      form: formData?.data,
    });
  }, [form, formData]);

  const formDataKeys = Object.keys(formData?.data ?? {}) || [];

  const createdAt =
    formData?.meta !== null ? dayjs().from(dayjs(formData?.meta?.created_at), true) : null;
  const editedAt =
    formData?.meta !== null ? dayjs().from(dayjs(formData?.meta?.updated_at), true) : null;
  const createdBy =
    formData?.meta !== null
      ? formData?.meta?.created_by?.first_name
        ? formData?.meta?.created_by?.first_name
        : formData?.meta?.created_by?.email
      : null;
  const editedBy =
    formData?.meta !== null
      ? formData?.meta?.last_modified_by?.first_name
        ? formData?.meta?.last_modified_by?.first_name
        : formData?.meta?.last_modified_by?.email
      : null;
  const status = formData?.meta !== null ? formData?.meta?.status : 'New Document';

  const getValidationRules = (item) => {
    const required = item?.validation?.required && !item?.validation?.is_password;
    return [
      {
        required,
        message: `${capitalize(item.identifier)} is required`,
      },
    ];
  };

  const getFormField = (field_item) => {
    const field_Type = field_item?.field_type;
    switch (field_Type) {
      case 'text':
        return <Input disabled={disableInput} />;
      case 'multiline':
        return <ReactDraft disabled={disableInput} />;
      case 'date':
        return <CustomDatePicker disabled={disableInput} />;
      case 'number':
        return <InputNumber style={{ width: '100%' }} disabled={disableInput} />;
      case 'boolean':
        return <BooleanSelector disabled={disableInput} />;
      case 'media':
        return <ImageUploaderModal field_item={field_item} disabled={disableInput} />;
      case 'geo':
        return <InputGeoField field_item={field_item} disabled={disableInput} />;
      case 'object':
      case 'repeated':
        return <RepeatedFieldsGenerator field_item={field_item} disabled={disableInput} />;
      case 'list': {
        const list = field_item?.validation?.fixed_list_elements || [];
        const multi_choise = field_item?.validation?.is_multi_choice;

        if (list.length > 0 && !multi_choise) {
          return <FixedListSelector list={list} disabled={disableInput} />;
        }
        if (list.length > 0 && multi_choise) {
          return <Checkbox.Group options={list} disabled={disableInput} />;
        }
        if (list.length === 0 && !multi_choise) {
          return <DynamicFormList field_item={field_item} />;
        }
        break;
      }
      default:
        break;
    }
  };

  const renderForm = (modelInfo) => {
    return modelInfo.fields.map((item, i) => {
      if (formDataKeys.includes(item.identifier)) {
        if (item?.field_type === 'multiline') {
          // has initial data
          return (
            <Form.Item
              key={i}
              //initialValue={formData?.data[`${item.identifier}`]?.html ?? undefined}
              name={['form', `${item.identifier}`, 'html']}
              label={generateLabel(item)}
              tooltip={item.description}
              rules={getValidationRules(item)}
            >
              {getFormField(item)}
            </Form.Item>
          );
        }
        if (item?.field_type === 'text' && item?.validation?.is_password) {
          return (
            <Form.Item
              key={i}
              name={['form', `${item.identifier}`]}
              label={generateLabel(item)}
              tooltip={item.description}
              rules={getValidationRules(item)}
            >
              {getFormField(item)}
            </Form.Item>
          );
        }
        if (item?.field_type === 'geo') {
          const { lat, lon } = formData?.data[`${item.identifier}`] ?? {};
          const initData =
            lat || lon
              ? {
                  lat,
                  lon,
                }
              : undefined;
          return (
            <Form.Item
              key={i}
              //initialValue={initData || undefined}
              name={['form', `${item.identifier}`]}
              label={generateLabel(item)}
              tooltip={item.description}
            >
              {getFormField(item)}
            </Form.Item>
          );
        }
        if (item?.field_type === 'repeated') {
          return (
            <Form.Item
              key={i}
              //initialValue={formData?.data[`${item.identifier}`] ?? undefined}
              name={['form', `${item.identifier}`]}
              label={generateLabel(item)}
              tooltip={item.description}
            >
              {getFormField(item)}
            </Form.Item>
          );
        }

        return (
          <Form.Item
            key={i}
            //initialValue={formData?.data[`${item.identifier}`] ?? undefined}
            name={['form', `${item.identifier}`]}
            label={generateLabel(item)}
            tooltip={item.description}
            rules={getValidationRules(item)}
          >
            {getFormField(item)}
          </Form.Item>
        );
      } // has no initial data
      if (item?.field_type === 'multiline') {
        return (
          <Form.Item
            key={i}
            name={['form', `${item.identifier}`, 'html']}
            label={generateLabel(item)}
            tooltip={item.description}
            rules={getValidationRules(item)}
          >
            {getFormField(item)}
          </Form.Item>
        );
      }
      if (item?.field_type === 'geo') {
        return (
          <Form.Item
            key={i}
            name={['form', `${item.identifier}`]}
            label={generateLabel(item)}
            tooltip={item.description}
          >
            {getFormField(item)}
          </Form.Item>
        );
      }

      // <div>{field_item?.validation?.locals.map(l => <Tag icon={l == local ? <CheckCircleOutlined/> : null} color={l == local ? '#e73a55' : 'default'}>{l}</Tag>)}</div>
      // <Radio.Group value={local} style={{display: 'flex'}}>{ field_item?.validation?.locals.map( l => <div><Radio value={l}>{l}</Radio></div>)}</Radio.Group>

      return (
        <Form.Item
          key={i}
          name={['form', `${item.identifier}`]}
          label={generateLabel(item)}
          rules={getValidationRules(item)}
          tooltip={item.description}
        >
          {getFormField(item)}
        </Form.Item>
      );
    });
  };

  const connectDisconnetChecker = (data) => (Object.keys(data)?.length ? data : undefined);

  const [editData, { loading }] = useUpdateContentFormMutation({
    onCompleted: (data) => {
      successMessage({
        isLocal: true,
        local,
      });
      onContentEdited(data?.upsertModelData); // pass the data
    },
    // update: updateCache
  });

  const [createData, { loading: createLoading }] = useCreateModelDataMutation({
    onCompleted: (data) => {
      successMessage(`New ${modelInfo.name} created successfully!`);
      onContentCreated(data?.upsertModelData); // pass the data
    },
    // update: updateCache
  });

  function removeNulls2(obj) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === 'object') {
        const childObject = removeNulls2(obj[key]);
        if (childObject === undefined) {
          delete obj[key];
        }
      } else if (obj[key] === '' || obj[key] === null || obj[key] === undefined) {
        delete obj[key];
      }
    });
    return Object.keys(obj).length > 0 || obj instanceof Array ? obj : undefined;
  }

  /*function removeNulls(obj) {
    let isArray = obj instanceof Array;
    for (let k in obj) {
      if (obj[k] === null) isArray ? obj.splice(k, 1) : delete obj[k];
      else if (typeof obj[k] == 'object') removeNulls(obj[k]);
      if (isArray && obj.length == k) removeNulls(obj);
    }
    return obj;
  }*/

  const onUpdate = async ({ status }) => {
    try {
      const { form: newFormData } = await form.validateFields();

      console.log('data data', newFormData.date);

      const connect_disconnect_data: {
        connect_ids: string;
        disconnect_ids: string;
        media_data: string;
      } = client.cache.readQuery({
        query: CONNECT_DISCONNECT_QUERY,
      });
      const cache_media_data: { media_data: string } = client.cache.readQuery({
        query: CONTENT_MEDIA_QUERY,
      });

      const connect_ids = connect_disconnect_data.connect_ids || {};
      const disconnect_ids = connect_disconnect_data.disconnect_ids || {};
      const media_data = cache_media_data.media_data || {};

      // const connect = Object.keys(connect_ids).filter(item => disconnect_ids[item].length > 0)
      const disconnectt = Object.keys(disconnect_ids).filter(
        (item) => disconnect_ids[item].length > 0
      );

      setConnect(connect_ids);
      setMediaData(media_data);
      Object.keys(disconnectt).length > 0 ? setDisconnect(disconnect_ids) : setDisconnect({});
      // await setProgress(80);

      //let sanitizedData = removeNulls(newFormData);
      let sanitizedData = newFormData;

      if (formType === 'edit') {
        await editData({
          variables: {
            status,
            _id: formData?._key,
            model_name: formData?.type,
            single_page_data: contentData?.single_page_data,
            local,
            payload: {
              ...sanitizedData,
              ...mediaData,
            },
            connect: connectDisconnetChecker(connect),
            disconnect: connectDisconnetChecker(disconnect),
          },
        });
      } else {
        await createData({
          variables: {
            status,
            model_name: `${modelInfo?.name}`,
            payload: { ...sanitizedData, ...mediaData },
            local,
            connect,
          },
        });
      }
      // await setProgress(0);
      await setFormErrors([]);
      // await form.resetFields();
    } catch (e) {
      const errors = flatten(form.getFieldsError().map((error) => error.errors));
      setFormErrors(errors);
    }
  };

  if (modelInfo?.fields?.length === 0 || !modelInfo) return <Empty />;

  const onValuesChange = (values) => {
    console.log('Log: onValuesChange -> values', values);
  };

  return (
    <Layout style={{ backgroundColor: 'white' }}>
      <LoadingBar color="#f11946" progress={progress} />
      <Content key={`${formData?._key}${local}`} className={styles.contentFormContainer}>
        <Form
          {...layout}
          name="content-form"
          className="apitoCreateFormHideValidation"
          layout="vertical"
          form={form}
          // onPublish={onPublish}
          validateMessages={validateMessages}
          //initialValues={formData?.data}
          // onValuesChange={onValuesChange}
        >
          <div style={{ marginBottom: '15px' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'right',
                marginBottom: '10px',
                width: '100%',
                justifyContent: 'flex-end',
              }}
            >
              {contentData?.revision ? (
                <Button type="primary">
                  {`RESTORE THIS ${(
                    localsData?.[`${local}`] ||
                    local ||
                    ''
                  ).toUpperCase()} REVISION`}
                </Button>
              ) : (
                <Space>
                  <Button
                    onClick={() => {
                      onUpdate({ status: 'draft' });
                      setSubmitType('draft');
                    }}
                    icon={<EditOutlined />}
                    loading={submitType === 'draft' && (loading || createLoading)}
                  >
                    {formType === 'edit' && status === 'draft' ? 'UPDATE DRAFT' : 'SAVE AS DRAFT'}
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      onUpdate({ status: 'published' });
                      setSubmitType('publish');
                    }}
                    icon={<CloudUploadOutlined />}
                    loading={submitType === 'publish' && (loading || createLoading)}
                  >
                    {formType === 'edit' && status === 'draft' ? 'PUBLISH DRAFT' : 'PUBLISH'}
                  </Button>
                </Space>
              )}
            </div>

            {formErrors.length > 0
              ? formErrors.map((e) => (
                  <div style={{ paddingBottom: 5 }}>
                    <Alert message={e} type="error" showIcon />
                  </div>
                ))
              : null}
          </div>
          <div style={{ marginTop: '5px' }}>{renderForm(modelInfo)}</div>
          {/* {formType === 'create' && (
            <div style={{ marginTop: '5px' }}>{renderForm(projectModelInfo)}</div>
          )} */}
        </Form>
      </Content>
      {formType === 'edit' ? (
        <Sider
          style={{
            marginLeft: '25px',
            backgroundColor: 'white',
          }}
          width="25%"
        >
          <Descriptions size="small" column={1} layout="vertical" bordered>
            <Descriptions.Item
              label={
                <div>
                  <img
                    src="/static/pages/console/content/blue_circle.svg"
                    alt="p"
                    className={styles.metaIcon}
                  />
                  <Text> Created By</Text>
                </div>
              }
              labelStyle={{ fontWeight: '700' }}
            >
              {createdBy !== null ? (
                <Space size={1} direction="vertical">
                  <Text className={styles.metaTitle}>{createdBy || ''}</Text>
                  <Text type="secondary" className={styles.metaSubtitle}>
                    {createdAt} ago
                  </Text>
                </Space>
              ) : (
                '-'
              )}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <div>
                  <img
                    src="/static/pages/console/content/yellow_circle.svg"
                    alt="p"
                    className={styles.metaIcon}
                  />
                  <Text> Edited By</Text>
                </div>
              }
              labelStyle={{ fontWeight: '700' }}
            >
              <Space size={1} direction="vertical">
                {editedBy !== null ? (
                  <>
                    <Text className={styles.metaTitle}>{editedBy || ''}</Text>
                    <Text type="secondary" className={styles.metaSubtitle}>
                      {editedAt} ago
                    </Text>{' '}
                  </>
                ) : (
                  '-'
                )}
              </Space>
            </Descriptions.Item>
            <Descriptions.Item label="Status" labelStyle={{ fontWeight: '700' }}>
              <Tag color={status === 'published' ? 'green' : 'yellow'}>{status}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Localization" labelStyle={{ fontWeight: '700' }}>
              {localsArr.map((local) => (
                <Tag>{capitalize(localsData?.[`${local}`] || local || '')}</Tag>
              ))}
            </Descriptions.Item>
            {/* <Descriptions.Item label='Last Published' labelStyle={{ fontWeight: '700' }}>
                        22 days ago
                    </Descriptions.Item> */}
            <Descriptions.Item label="Content ID" labelStyle={{ fontWeight: '700' }}>
              {formData?._key}
            </Descriptions.Item>
            <Descriptions.Item label="Revision History" labelStyle={{ fontWeight: '700' }}>
              {formData && formData?.id && (
                <RevisionHistory model={modelInfo?.name} _id={formData?.id} status={status} />
              )}
            </Descriptions.Item>
          </Descriptions>
        </Sider>
      ) : null}
    </Layout>
  );
};

export default EditAndCreateContentForm;
