import { Skeleton } from 'antd';
import MainLayout from 'components/layouts/MainLayout';
import { useGetSettingsQuery, useUpdateSettingsMutation } from 'generated/graphql';
import { errorMessage, successMessage } from 'utils/message';
import GeneralForm from './GeneralForm';

const GeneralContainer = () => {
  const { data, loading, error } = useGetSettingsQuery();

  if (error) {
    errorMessage(error);
  }

  const [updateCurrentProject, submissionState] = useUpdateSettingsMutation({
    onCompleted: (data) => {
      successMessage('Project General Settings Updated Successfully');
      console.log('Completed');
    },
  });

  const onFinish = (values) => {
    console.log('Finish', values);
    if (Object.keys(values)?.length > 0) {
      updateCurrentProject({
        variables: {
          ...values,
        },
      });
    }
  };

  return (
    <MainLayout
      title="General"
      subtitle="General Project Settings"
      breadcrumList={['Setting', 'General']}
    >
      {loading ? (
        <Skeleton active />
      ) : (
        <GeneralForm
          project={data?.currentProject || {}}
          onFinish={onFinish}
          submissionLoading={submissionState?.loading}
        />
      )}
    </MainLayout>
  );
};

export default GeneralContainer;
